import React from "react";
import { TbMessageCircle2 } from "react-icons/tb";
import { FaUser } from "react-icons/fa";

const SceneElement = ({ element, index, setActiveElement, isActive }) => {
  const handleClick = () => {
    setActiveElement(index);
  };

  const elementClasses = `my-4 transition-all duration-300 ${
    isActive
      ? "bg-purple-500/10 p-4 rounded-lg border border-purple-500/20"
      : ""
  } cursor-pointer hover:bg-purple-500/5 rounded-lg`;

  switch (element.type) {
    case "action":
      return (
        <div
          key={index}
          className={`${elementClasses} text-gray-300`}
          onClick={handleClick}
        >
          {element.description}
        </div>
      );
    case "dialogue":
      return (
        <div
          key={index}
          className={`${elementClasses} p-4`}
          onClick={handleClick}
        >
          <div className="flex gap-4">
            {/* Character Avatar Section */}
            <div className="flex-shrink-0 h-full">
              <div className="sticky top-4">
                <div className="w-12 h-12 rounded-full bg-gradient-to-br from-purple-600 to-pink-600 flex items-center justify-center border-2 border-purple-300 shadow-lg shadow-purple-500/20">
                  <FaUser className="text-white text-xl" />
                </div>
                <div className="mt-2 w-12 text-center text-xs font-bold text-purple-300 bg-purple-950/50 py-1 rounded-md border border-purple-500/30">
                  {element.character}
                </div>
              </div>
            </div>

            {/* Dialogue Content */}
            <div className="flex-grow">
              {/* Video game style dialogue box */}
              <div className="relative">
                {/* Dialogue box with sharp angles */}
                <div className="bg-gradient-to-br from-gray-900/95 to-gray-800/95 p-4 rounded-lg border-2 border-purple-500/30 shadow-xl relative">
                  {/* Top accent line */}
                  <div className="absolute top-0 left-4 right-4 h-0.5 bg-gradient-to-r from-transparent via-purple-500/50 to-transparent"></div>

                  {/* Parenthetical */}
                  {element.parenthetical && (
                    <div className="text-pink-400/70 italic text-sm mb-2 font-gaming">
                      ({element.parenthetical})
                    </div>
                  )}

                  {/* Main dialogue text */}
                  <div className="text-gray-100 leading-relaxed text-lg">
                    {element.line}
                  </div>

                  {/* Bottom accent line */}
                  <div className="absolute bottom-0 left-4 right-4 h-0.5 bg-gradient-to-r from-transparent via-purple-500/50 to-transparent"></div>

                  {/* Decorative corner accents */}
                  <div className="absolute top-0 left-0 w-2 h-2 border-t-2 border-l-2 border-purple-500/50"></div>
                  <div className="absolute top-0 right-0 w-2 h-2 border-t-2 border-r-2 border-purple-500/50"></div>
                  <div className="absolute bottom-0 left-0 w-2 h-2 border-b-2 border-l-2 border-purple-500/50"></div>
                  <div className="absolute bottom-0 right-0 w-2 h-2 border-b-2 border-r-2 border-purple-500/50"></div>
                </div>

                {/* Triangle pointer */}
                <div className="absolute left-[-12px] top-6 w-4 h-4 transform rotate-45 bg-gradient-to-br from-gray-900/95 to-gray-800/95 border-l-2 border-b-2 border-purple-500/30"></div>
              </div>
            </div>
          </div>
        </div>
      );
    case "internal_monologue":
      return (
        <div
          key={index}
          className={`${elementClasses} p-4`}
          onClick={handleClick}
        >
          <div className="flex gap-4">
            {/* Character Avatar Section */}
            <div className="flex-shrink-0 h-full">
              <div className="sticky top-4 w-12 h-12 rounded-full bg-gradient-to-br from-gray-700/50 to-gray-600/50 flex items-center justify-center border border-gray-600/20">
                <FaUser className="text-gray-400/70 text-xl" />
              </div>
            </div>

            {/* Internal Monologue Content */}
            <div className="flex-grow">
              <div className="font-bold text-gray-400 flex items-center gap-2 mb-2">
                <TbMessageCircle2 className="text-sm" /> {element.character}
              </div>
              <div className="italic text-gray-400 bg-gray-800/50 p-3 rounded-lg border border-gray-700/30">
                {element.description}
              </div>
            </div>
          </div>
        </div>
      );
    case "transition":
      return (
        <div
          key={index}
          className={`${elementClasses} text-right text-pink-500/70 uppercase font-bold tracking-wider`}
          onClick={handleClick}
        >
          {element.description}
        </div>
      );
    default:
      return null;
  }
};

export default SceneElement;
