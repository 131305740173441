import React from "react";
import SceneMeta from "./SceneMeta";
import SceneElement from "./SceneElement";

const Scene = ({ elements, setActiveElement, activeElement }) => {
  return (
    <div className={`prose max-w-none`}>
      {/* <SceneMeta meta={scene.meta} /> */}
      <div className="scene-content">
        {elements?.map((element, elemIndex) => (
          <SceneElement
            key={elemIndex}
            element={element}
            index={elemIndex}
            setActiveElement={setActiveElement}
            isActive={activeElement === elemIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default Scene;
