import React, { useState, useEffect, useRef } from "react";
import useViewportHeight from "./utils/useViewportHeight";
import { Link, useLocation } from "react-router-dom";

export default function MainLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const isDarkMode = location.pathname === "/ai-story-generator/nsfw";
  const viewportHeight = useViewportHeight();

  const handleToggleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div
        className={`flex flex-col ${isDarkMode ? "bg-gray-900" : ""}`}
        style={{ height: `${viewportHeight}px` }}
      >
        <Link to="/">
          <span
            className={`inline-flex items-center text-sm font-semibold ${
              isDarkMode
                ? "text-gray-100 bg-gradient-to-r from-gray-800 to-gray-700"
                : "text-gray-800 bg-gradient-to-r from-blue-100 to-purple-100"
            } px-3 py-1 rounded-full shadow-sm cursor-pointer hover:shadow-md transition-shadow duration-300`}
          >
            let me
            <span className="mx-1 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 transition-all duration-300 ease-in-out">
              WRITE
            </span>
            for you
          </span>
        </Link>
        <div className="flex-grow overflow-auto">
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { darkMode: isDarkMode })
          )}
        </div>
      </div>
    </>
  );
}
