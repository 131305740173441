import React, { useState, useEffect, useRef } from "react";

const WordByWordRenderer = ({
  paragraphs,
  typingSpeed = 50,
  onWordRendered,
  onFinished, // Add this prop
}) => {
  const [displayedContent, setDisplayedContent] = useState([]);
  const currentParagraphIndexRef = useRef(0);
  const currentWordIndexRef = useRef(0);
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    const typeNextWord = () => {
      if (currentParagraphIndexRef.current < paragraphs.length) {
        const currentParagraph = paragraphs[currentParagraphIndexRef.current];
        const words = currentParagraph.split(" ");

        if (currentWordIndexRef.current < words.length) {
          setDisplayedContent((prev) => {
            const newContent = [...prev];
            if (!newContent[currentParagraphIndexRef.current]) {
              newContent[currentParagraphIndexRef.current] = "";
            }
            const currentText = newContent[currentParagraphIndexRef.current];
            const wordToAdd = words[currentWordIndexRef.current];
            if (!currentText.endsWith(wordToAdd + " ")) {
              newContent[currentParagraphIndexRef.current] += wordToAdd + " ";
            }
            return newContent;
          });
          currentWordIndexRef.current++;

          if (onWordRendered) {
            onWordRendered();
          }
        } else {
          currentParagraphIndexRef.current++;
          currentWordIndexRef.current = 0;
        }

        typingTimeoutRef.current = setTimeout(typeNextWord, typingSpeed);
      } else {
        // All content has been rendered
        if (onFinished) {
          onFinished();
        }
      }
    };

    // Clear any existing timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typeNextWord();

    // Cleanup function
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [paragraphs, typingSpeed, onWordRendered, onFinished]); // Add onFinished to dependencies

  return (
    <div>
      {displayedContent.map((paragraph, index) => (
        <p
          key={index}
          className="text-base sm:text-lg leading-relaxed text-gray-700 font-sans italic bg-white p-2 rounded shadow-sm"
        >
          {paragraph}
        </p>
      ))}
    </div>
  );
};

export default WordByWordRenderer;
