import React, { useState, useRef, useEffect } from "react";
import { FaPaperPlane, FaRobot } from "react-icons/fa";
import { getImprovedParagraphs } from "../api/paragraphs";
import ClearableTextarea from "./ClearableTextarea";

const ContentImprover = ({ content, onImprovement }) => {
  const chatHistoryScrollRef = useRef(null);
  const initialMessageAddedRef = useRef(false);
  const [userInput, setUserInput] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const [isImprovementLoading, setIsImprovementLoading] = useState(false);

  useEffect(() => {
    if (!initialMessageAddedRef.current && content) {
      const initialMessage = {
        type: "assistant",
        text: "Here's the original content:",
        timestamp: new Date(),
        improvedContent: [content],
        contentId: "original",
      };
      setChatHistory([initialMessage]);
      setSelectedVersionId("original");
      initialMessageAddedRef.current = true;
    }
  }, [content]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }, [showChatHistory]);

  const scrollToBottom = () => {
    if (chatHistoryScrollRef.current && showChatHistory) {
      chatHistoryScrollRef.current.scrollTo({
        top: chatHistoryScrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleAssistantSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setIsImprovementLoading(true);

    const userMessage = {
      type: "user",
      text: userInput,
      timestamp: new Date(),
    };

    setChatHistory((prev) => [...prev, userMessage]);
    setTimeout(() => {
      scrollToBottom();
    }, 500);

    try {
      if (content.length == 0) {
        console.error("Content cannot be 0");
        return;
      }
      let num_paragraphs = content.length;
      let paragraphs = content.join("\n\n");
      const improvedContent = await getImprovedParagraphs(
        userInput,
        paragraphs,
        num_paragraphs,
        {},
        (progress) => {
          //   console.log(progress);
          onImprovement({ content: progress, id: "loading" });
        },
        true,
        false
      );

      console.log(improvedContent);

      const contentId = Date.now().toString();
      const aiMessage = {
        type: "assistant",
        text: "I've improved the content based on your request.",
        timestamp: new Date(),
        improvedContent: improvedContent,
        contentId: contentId,
      };

      setChatHistory((prev) => [...prev, aiMessage]);
      setTimeout(() => {
        scrollToBottom();
      }, 500);

      onImprovement({ content: improvedContent, id: contentId });
      setSelectedVersionId(contentId);
    } catch (error) {
      const errorMessage = {
        type: "assistant",
        text: `Sorry, there was an error: ${error.message}`,
        timestamp: new Date(),
      };
      setChatHistory((prev) => [...prev, errorMessage]);
    } finally {
      setIsProcessing(false);
      setIsImprovementLoading(false);
      setUserInput("");
      setShowChatHistory(true);
    }
  };

  const handleVersionSelect = (version) => {
    if (isImprovementLoading) return;

    onImprovement(version);
    setSelectedVersionId(version.id);
  };

  return (
    <div className="flex flex-col">
      <div className="border-t pt-1 sm:pt-2 flex flex-col">
        {/* Chat History */}
        {showChatHistory && (
          <div
            ref={chatHistoryScrollRef}
            className="mb-2 sm:mb-4 max-h-[150px] sm:max-h-[200px] overflow-y-auto border rounded-lg bg-white p-2 sm:p-3 text-sm sm:text-base"
          >
            {chatHistory.map((message, index) => (
              <div
                key={index}
                className={`mb-2 sm:mb-3 ${
                  message.type === "user" ? "text-right" : "text-left"
                }`}
              >
                <div
                  className={`inline-block max-w-[90%] p-1.5 sm:p-2 rounded-lg ${
                    message.type === "user"
                      ? "bg-blue-500 text-white"
                      : message.contentId === selectedVersionId
                      ? "bg-green-50 border-2 border-green-300 text-gray-800"
                      : "bg-gray-100 text-gray-800"
                  }`}
                >
                  <p className="text-sm sm:text-base">{message.text}</p>
                  {message.improvedContent && (
                    <button
                      onClick={() =>
                        handleVersionSelect({
                          content: message.improvedContent,
                          id: message.contentId,
                        })
                      }
                      disabled={isImprovementLoading}
                      className={`mt-1 sm:mt-2 text-xs sm:text-sm ${
                        isImprovementLoading
                          ? "text-gray-400 cursor-not-allowed"
                          : message.contentId === selectedVersionId
                          ? "text-green-600 font-medium"
                          : "underline hover:no-underline"
                      }`}
                    >
                      {message.contentId === selectedVersionId
                        ? isImprovementLoading
                          ? "Improving..."
                          : "Currently viewing"
                        : "View this version"}
                    </button>
                  )}
                </div>
                <div className="text-[10px] sm:text-xs text-gray-500 mt-0.5 sm:mt-1">
                  {message.timestamp.toLocaleTimeString()}
                </div>
              </div>
            ))}
            {chatHistory.length == 0 && (
              <div className="text-center text-sm text-gray-500 mt-2 sm:mt-3">
                No chat history yet.
              </div>
            )}
          </div>
        )}

        {/* Input Form with inline history toggle */}
        <form onSubmit={handleAssistantSubmit}>
          <div className="flex items-center gap-1 sm:gap-2">
            <button
              type="button"
              onClick={() => setShowChatHistory(!showChatHistory)}
              className={`flex-none flex items-center gap-1 px-1.5 py-1.5 text-sm sm:text-base rounded-md transition-all
                    ${
                      showChatHistory
                        ? "bg-blue-50 text-blue-600 border border-blue-200 hover:bg-blue-100 hover:border-blue-300"
                        : "text-gray-500 hover:text-gray-700 border border-gray-200 hover:border-gray-300"
                    }`}
              title={
                showChatHistory ? "Hide chat history" : "Show chat history"
              }
            >
              <FaRobot
                className={`text-xs sm:text-sm ${
                  showChatHistory ? "text-blue-500" : "text-gray-400"
                }`}
              />
              <span className="hidden sm:inline text-xs">
                {showChatHistory ? "Hide" : "Show"}
              </span>
            </button>
            <ClearableTextarea
              value={userInput}
              onChange={(value) => setUserInput(value)}
              placeholder="How would you like to improve this content?"
              className="w-full text-sm border border-yellow-300 rounded-md focus:ring-2 focus:ring-yellow-400 focus:border-transparent pr-12"
              disabled={isProcessing}
              rows={2}
            />
            <button
              type="submit"
              disabled={isProcessing || !userInput.trim()}
              className="flex-none px-2 sm:px-4 py-1.5 sm:py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              {isProcessing ? (
                <div className="flex items-center gap-1 sm:gap-2">
                  <div className="animate-spin h-3 w-3 sm:h-4 sm:w-4 border-2 border-white border-t-transparent rounded-full" />
                  <span className="hidden sm:inline">Processing...</span>
                </div>
              ) : (
                <div className="flex items-center gap-1 sm:gap-2">
                  <FaPaperPlane className="text-sm sm:text-base" />
                  <span className="hidden sm:inline">Send</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContentImprover;
