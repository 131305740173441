import React, { useState } from "react";
import { FiEdit2, FiCheck, FiX } from "react-icons/fi";

const StoryDetails = ({
  premise,
  genre,
  onUpdate,
  isFinal,
  darkMode = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPremise, setEditedPremise] = useState(premise);
  const [editedGenre, setEditedGenre] = useState(genre);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(editedPremise, editedGenre);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedPremise(premise);
    setEditedGenre(genre);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="premise"
            className={`block text-sm font-medium mb-1 ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Story Premise
          </label>
          <textarea
            id="premise"
            value={editedPremise}
            onChange={(e) => setEditedPremise(e.target.value)}
            className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent ${
              darkMode
                ? "bg-gray-700 border-gray-600 text-gray-100"
                : "bg-white border-gray-300 text-gray-900"
            }`}
            rows={3}
          />
        </div>
        <div>
          <label
            htmlFor="genre"
            className={`block text-sm font-medium mb-1 ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Genre (optional)
          </label>
          <input
            type="text"
            id="genre"
            value={editedGenre}
            onChange={(e) => setEditedGenre(e.target.value)}
            className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent ${
              darkMode
                ? "bg-gray-700 border-gray-600 text-gray-100"
                : "bg-white border-gray-300 text-gray-900"
            }`}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={handleCancel}
            className={`p-2 rounded-full ${
              darkMode
                ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
          >
            <FiX size={18} />
          </button>
          <button
            type="submit"
            className={`p-2 rounded-full ${
              darkMode
                ? "bg-amber-600 text-white hover:bg-amber-700"
                : "bg-amber-500 text-white hover:bg-amber-600"
            }`}
          >
            <FiCheck size={18} />
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="relative group">
      <div
        className={`prose max-w-none ${
          darkMode ? "text-gray-300" : "text-gray-700"
        }`}
      >
        <p className="text-base sm:text-lg leading-relaxed mb-1">
          {premise || "No premise set"}
        </p>
        {genre && (
          <p
            className={`text-sm ${
              darkMode ? "text-gray-400" : "text-gray-500"
            } italic`}
          >
            Genre: {genre}
          </p>
        )}
      </div>
      {!isFinal && (
        <button
          onClick={() => setIsEditing(true)}
          className={`absolute top-0 right-0 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 ${
            darkMode
              ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
              : "bg-gray-100 text-gray-600 hover:bg-gray-200"
          }`}
        >
          <FiEdit2 size={18} />
        </button>
      )}
    </div>
  );
};

export default StoryDetails;
