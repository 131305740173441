import React, { useState, useEffect, useRef } from "react";
import Scene from "./Scene";

const SceneWordByWordRenderer = ({
  elements,
  typingSpeed = 50,
  dialogueTypingSpeed = 100,
  dialoguePause = 500,
  onComplete,
  onWordRendered,
}) => {
  const [displayedElements, setDisplayedElements] = useState([]);
  const currentElementIndexRef = useRef(0);
  const currentWordIndexRef = useRef(0);
  const typingTimeoutRef = useRef(null);
  const dialogueStageRef = useRef("character"); // 'character', 'parenthetical', or 'line'
  const containerRef = useRef(null);

  useEffect(() => {
    const typeNextWord = () => {
      if (currentElementIndexRef.current >= elements.length) {
        if (onComplete) onComplete();
        return;
      }

      const currentElement = elements[currentElementIndexRef.current];
      let words;

      switch (currentElement.type) {
        case "action":
        case "internal_monologue":
        case "transition":
          words = currentElement.description.split(" ");
          break;
        case "dialogue":
          if (dialogueStageRef.current === "character") {
            words = [currentElement.character];
          } else if (
            dialogueStageRef.current === "parenthetical" &&
            currentElement.parenthetical
          ) {
            words = [currentElement.parenthetical];
          } else {
            words = currentElement.line.split(" ");
          }
          break;
        default:
          words = [];
      }

      if (currentWordIndexRef.current < words.length) {
        setDisplayedElements((prev) => {
          const newElements = [...prev];
          const newWord = words[currentWordIndexRef.current];

          if (!newElements[currentElementIndexRef.current]) {
            newElements[currentElementIndexRef.current] = { ...currentElement };
            if (currentElement.type === "dialogue") {
              newElements[currentElementIndexRef.current].character = "";
              newElements[currentElementIndexRef.current].parenthetical = "";
              newElements[currentElementIndexRef.current].line = "";
            } else {
              newElements[currentElementIndexRef.current].description = "";
            }
          }

          if (currentElement.type === "dialogue") {
            if (dialogueStageRef.current === "character") {
              newElements[currentElementIndexRef.current].character = newWord;
            } else if (dialogueStageRef.current === "parenthetical") {
              newElements[currentElementIndexRef.current].parenthetical =
                newWord;
            } else {
              newElements[currentElementIndexRef.current] = {
                ...newElements[currentElementIndexRef.current],
                line:
                  newElements[currentElementIndexRef.current].line +
                  (currentWordIndexRef.current > 0 ? " " : "") +
                  newWord,
              };
            }
          } else {
            newElements[currentElementIndexRef.current] = {
              ...newElements[currentElementIndexRef.current],
              description:
                newElements[currentElementIndexRef.current].description +
                (currentWordIndexRef.current > 0 ? " " : "") +
                newWord,
            };
          }

          return newElements;
        });

        currentWordIndexRef.current++;
        const currentSpeed =
          currentElement.type === "dialogue"
            ? dialogueTypingSpeed
            : typingSpeed;
        typingTimeoutRef.current = setTimeout(() => {
          typeNextWord();
          if (onWordRendered) onWordRendered();
        }, currentSpeed);
      } else {
        if (currentElement.type === "dialogue") {
          if (dialogueStageRef.current === "character") {
            dialogueStageRef.current = currentElement.parenthetical
              ? "parenthetical"
              : "line";
            currentWordIndexRef.current = 0;
            typingTimeoutRef.current = setTimeout(typeNextWord, dialoguePause);
          } else if (dialogueStageRef.current === "parenthetical") {
            dialogueStageRef.current = "line";
            currentWordIndexRef.current = 0;
            typingTimeoutRef.current = setTimeout(typeNextWord, dialoguePause);
          } else {
            currentElementIndexRef.current++;
            currentWordIndexRef.current = 0;
            dialogueStageRef.current = "character";
            if (elements[currentElementIndexRef.current]?.type === "dialogue") {
              typingTimeoutRef.current = setTimeout(
                typeNextWord,
                dialoguePause
              );
            } else {
              typeNextWord();
            }
          }
        } else {
          currentElementIndexRef.current++;
          currentWordIndexRef.current = 0;
          if (elements[currentElementIndexRef.current]?.type === "dialogue") {
            typingTimeoutRef.current = setTimeout(typeNextWord, dialoguePause);
          } else {
            typeNextWord();
          }
        }
      }
    };

    typeNextWord();

    return () => {
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    };
  }, [
    elements,
    typingSpeed,
    dialogueTypingSpeed,
    dialoguePause,
    onComplete,
    onWordRendered,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [displayedElements]);

  return (
    <div
      ref={containerRef}
      className="h-full overflow-y-auto scroll-smooth pr-4 scrollbar-thin scrollbar-thumb-purple-500/20 scrollbar-track-gray-800/20 hover:scrollbar-thumb-purple-500/30"
    >
      <Scene elements={displayedElements} />
    </div>
  );
};

export default SceneWordByWordRenderer;
