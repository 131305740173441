export const getStoryGeneratorHelmet = (isNsfw = false) => {
  if (isNsfw) {
    return {
      title: "NSFW Story Generator | Adult Content Creation with AI",
      description:
        "Create adult-oriented stories with our AI Story Generator. Generate mature content with customizable settings. Must be 18+ to use this feature.",
      keywords:
        "NSFW story generator, adult content, mature stories, AI story creation, adult fiction",
      canonicalUrl: "https://letmewriteforyou.xyz/ai-story-generator/nsfw",
      robots: "noindex, nofollow",
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: "/favicon.ico",
        },
      ],
    };
  }

  return {
    title: "AI Story Generator | Create Unique Stories with AI",
    description:
      "Use our AI Story Generator to create unique, engaging stories with artificial intelligence. Generate creative premises and expand your narrative effortlessly.",
    keywords:
      "AI story generator, artificial intelligence, creative writing, story creation, rewrite story",
    canonicalUrl: "https://letmewriteforyou.xyz/ai-story-generator",
    robots: "index, follow",
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: "/favicon.ico",
      },
    ],
  };
};

export const getLandingPageHelmet = () => {
  return {
    title: "Let Me Write For You | AI-Powered Writing Assistant",
    description:
      "Transform your ideas into beautifully written stories, screenplays, and reports with our AI writing assistant. Experience natural, creative writing enhanced by artificial intelligence.",
    keywords:
      "AI writing assistant, story generator, creative writing, screenplay writing, report writing, artificial intelligence writer",
    canonicalUrl: "https://letmewriteforyou.xyz",
    robots: "index, follow",
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: "/favicon.ico",
      },
    ],
  };
};

export const getStoriesPageHelmet = () => {
  return {
    title: "Sample Stories | AI-Generated Writing Examples",
    description:
      "Browse through a collection of AI-generated stories, showcasing the capabilities of our writing assistant. Explore various genres and writing styles.",
    keywords:
      "AI stories, writing samples, story examples, AI-generated content, creative writing examples",
    canonicalUrl: "https://letmewriteforyou.xyz/stories",
    robots: "index, follow",
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: "/favicon.ico",
      },
    ],
  };
};
