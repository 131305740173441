import { streamedApiCall, regularApiCall } from "../utils/api_utils";

const API_VER = "api/v1/";
const API_ENDPOINTS = {
  CONTINUE_SCENE: API_VER + "scenes/continue",
  EDIT_SCENE: API_VER + "scenes/edit",
};

// Simulated scene generation for testing
const simulateSceneGeneration = (premise, onChunk) => {
  const scene = {
    meta: {
      previous_screenplay_notes: {
        type: "notes",
        text: "New scene starting",
      },
      title: {
        type: "title",
        text: "The Coffee Shop Meeting",
      },
      setting: {
        location: "DOWNTOWN COFFEE SHOP - MORNING",
        time: "8:30 AM",
        description:
          "A bustling independent coffee shop with exposed brick walls, vintage furniture, and the rich aroma of freshly ground coffee beans.",
      },
      characters: [
        {
          type: "character",
          name: "SARAH",
          description:
            "Mid-30s, creative professional, dressed in casual business attire with artistic flair",
        },
        {
          type: "character",
          name: "MARCUS",
          description:
            "Late 20s, tech entrepreneur, wearing a well-fitted blazer over a t-shirt",
        },
      ],
    },
  };

  // Simulate streaming chunks of scene elements
  const elements = [
    {
      type: "action",
      description:
        "Sarah sits at a corner table, nervously tapping her fingers on her laptop. The morning rush creates a symphony of coffee grinders and chattering customers.",
    },
    {
      type: "action",
      description:
        "The door chimes as MARCUS enters, scanning the room with purpose.",
    },
    {
      type: "dialogue",
      character: "SARAH",
      line: "Marcus? Over here!",
      parenthetical: "waving slightly",
    },
    {
      type: "action",
      description:
        "Marcus weaves through the crowd, coffee cup holders swaying in his hands.",
    },
    {
      type: "dialogue",
      character: "MARCUS",
      line: "I got your usual - oat milk latte, extra shot.",
      parenthetical: "setting down the drinks",
    },
    {
      type: "internal_monologue",
      character: "SARAH",
      description: "He remembered my order. After all this time...",
    },
    {
      type: "dialogue",
      character: "SARAH",
      line: "Thanks. I wasn't sure you'd come.",
      parenthetical: "avoiding eye contact",
    },
    {
      type: "transition",
      description:
        "The bustling atmosphere fades into the background as tension fills their small corner of the café.",
    },
  ];

  // Send meta information first
  setTimeout(() => {
    onChunk({ chunk: JSON.stringify(scene) });
  }, 100);

  // Send each element with a delay to simulate streaming
  elements.forEach((element, index) => {
    setTimeout(() => {
      onChunk({ chunk: JSON.stringify(element) });
      if (index === elements.length - 1) {
        setTimeout(() => {
          onChunk({ chunk: "[DONE]" });
        }, 500);
      }
    }, (index + 2) * 1000);
  });
};

export const cancelGeneration = async () => {
  try {
    await regularApiCall(
      `${process.env.REACT_APP_API_URL}/${API_VER}cancel`,
      "POST"
    );
  } catch (error) {
    console.error("Error cancelling generation:", error);
    throw error;
  }
};

export const generateNewScene = async (
  instruction = "",
  count = 10,
  context = {},
  onProgress,
  stream = true,
  isNsfw = false
) => {
  return new Promise(async (resolve, reject) => {
    let scene = {
      meta: null,
      elements: [],
    };
    let currentJsonString = "";

    const sendUpdate = () => {
      onProgress([scene]);
    };

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          resolve([scene]);
        } else if (data.chunk === "[CANCELLED]") {
          reject(new Error("Generation cancelled"));
        } else {
          try {
            const parsedChunk = JSON.parse(data.chunk);

            if (parsedChunk.meta) {
              scene.meta = parsedChunk.meta;
              sendUpdate();
            } else if (parsedChunk.type) {
              scene.elements.push(parsedChunk);
              sendUpdate();
            }
          } catch (e) {
            currentJsonString += data.chunk;
            try {
              if (currentJsonString.trim().endsWith("}")) {
                const parsedObject = JSON.parse(currentJsonString);
                if (parsedObject.type) {
                  scene.elements.push(parsedObject);
                  currentJsonString = "";
                  sendUpdate();
                }
              }
            } catch (err) {
              // Continue accumulating if we don't have a complete JSON object yet
            }
          }
        }
      }
    };

    // Use the simulation instead of actual API call
    // simulateSceneGeneration(instruction, onChunk);

    try {
      let response = await regularApiCall(
        `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.CONTINUE_SCENE}`,
        "POST",
        {
          context,
          instruction,
          count,
          stream: false,
        }
      );
      // console.log(response);
      if (response.error) {
        reject(response.error);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const editScene = async (sceneContent, instruction, onProgress) => {
  return new Promise((resolve, reject) => {
    let editedScene = "";

    const sendUpdate = () => {
      onProgress(editedScene.trim());
    };

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          resolve(editedScene.trim());
        } else if (data.chunk === "[CANCELLED]") {
          reject(new Error("Generation cancelled"));
        } else {
          editedScene += data.chunk + " ";
          sendUpdate();
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.EDIT_SCENE}`,
      "POST",
      {
        sceneContent,
        instruction,
        isNsfw: false,
        stream: true,
      },
      onChunk,
      onError
    );
  });
};
