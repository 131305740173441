import React from "react";
import { Helmet } from "react-helmet";
import LandingPage from "./views/LandingPage";
import AIStoryGenerator from "./views/AIStoryGenerator";
import AISceneGenerator from "./views/AISceneGenerator";
import StoriesPage from "./views/StoriesPage";
import AdminPage from "./views/AdminPage";
import APITest from "./views/APITest";
import { StoryProvider } from "./context/StoryContext";
import { SceneProvider } from "./context/SceneContext";
import PromptEditor from "./components/PromptEditor";
import MainLayout from "./MainLayout";
import PublishedStoryView from "./views/PublishedStoryView";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import WordByWordDemo from "./views/WordByWordDemo";
import { useState } from "react";
import SceneWordByWordDemo from "./views/SceneWordByWordDemo";

function App() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <StoryProvider>
      <SceneProvider>
        <Router>
          <Helmet>
            <html lang="en" />
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="theme-color" content="#000000" />
            <link
              rel="canonical"
              href="https://letmewriteforyou.xyz/ai-story-generator"
            />
          </Helmet>
          <MainLayout>
            <Switch>
              <Route path="/" exact>
                <LandingPage />
              </Route>
              <Route path="/ai-story-generator" exact>
                <AIStoryGenerator darkMode={darkMode} />
              </Route>
              <Route path="/ai-story-generator/nsfw">
                <AIStoryGenerator darkMode={true} isNsfw={true} />
              </Route>
              <Route path="/ai-scene-generator" component={AISceneGenerator} />
              <Route path="/stories" exact component={StoriesPage} />
              <Route path="/admin" exact component={AdminPage} />
              <Route path="/prompts" exact component={PromptEditor} />
              <Route path="/testapi" exact component={APITest} />
              <Route path="/word-demo" component={WordByWordDemo} />
              <Route path="/story/:id" component={PublishedStoryView} />
              <Route path="/scene-word-demo" component={SceneWordByWordDemo} />
            </Switch>
          </MainLayout>
        </Router>
      </SceneProvider>
    </StoryProvider>
  );
}

export default App;
