import React, { useState, useRef, useEffect } from "react";
import ContentImprover from "./ContentImprover";
import { FaCheck, FaTimes, FaFastForward, FaUndo } from "react-icons/fa";
import WordByWordRenderer from "./WordByWordRenderer";

const DraftContentRenderer = ({
  draftContent,
  inProgress,
  onFinalize,
  onReject,
}) => {
  const contentRef = useRef(null);
  const [showWordByWord, setShowWordByWord] = useState(true);
  const [isWordByWordComplete, setIsWordByWordComplete] = useState(false);

  const [content, setContent] = useState({
    content: draftContent,
    id: "initial",
  });

  useEffect(() => {
    setContent({ content: draftContent, id: "initial" });
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    }, 100);
  }, [draftContent]);

  const handleReject = () => {
    setIsWordByWordComplete(false);
    setShowWordByWord(true);
    onReject();
  };

  const handleFinalize = () => {
    onFinalize(content.content);
  };

  const ActionButtons = () => {
    return (
      <div className="flex-1 flex gap-2">
        <button
          onClick={handleFinalize}
          disabled={content.id === "loading"}
          className={`flex-1 px-2 py-1 bg-green-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center ${
            content.id === "loading" ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <FaCheck className="mr-1" /> Accept
        </button>
        <button
          onClick={handleReject}
          disabled={content.id === "loading"}
          className={`flex-1 px-2 py-1 bg-red-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center ${
            content.id === "loading" ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <FaTimes className="mr-1" /> Reject
        </button>
      </div>
    );
  };

  const ToggleButton = () => (
    <button
      onClick={() => setShowWordByWord(!showWordByWord)}
      className="absolute top-0 right-1 px-3 py-1 bg-gray-800/70 hover:bg-gray-800/90 text-white rounded-full transition-colors duration-200"
    >
      {showWordByWord ? (
        <>
          <FaFastForward className="w-3 h-3" />
        </>
      ) : (
        <>
          <FaUndo className="w-3 h-3" />
        </>
      )}
    </button>
  );

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  };

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const handleImprovement = (improvement) => {
    setContent(improvement);
    scrollToTop();
  };

  const renderContent = () => {
    if (content.id === "loading") {
      return content.content.map((paragraph, index) => (
        <div key={index} className="relative">
          <p className="text-base sm:text-lg leading-relaxed text-gray-700 font-sans italic bg-white p-2 rounded shadow-sm">
            {paragraph}
          </p>
          <div className="absolute top-0 left-0 right-0 bg-white/50 flex items-center justify-center p-2 z-50">
            <div className="flex items-center gap-2">
              <div className="animate-spin h-4 w-4 border-2 border-blue-500 border-t-transparent rounded-full"></div>
              <span className="text-sm text-blue-600 font-medium">
                Improving...
              </span>
            </div>
          </div>
        </div>
      ));
    }

    return (
      Array.isArray(content.content) &&
      (showWordByWord ? (
        <WordByWordRenderer
          paragraphs={content.content}
          typingSpeed={200}
          onWordRendered={() => {
            scrollToBottom();
          }}
          onFinished={() => {
            setIsWordByWordComplete(true);
            setShowWordByWord(false);
          }}
        />
      ) : (
        content.content.map((paragraph, index) => (
          <p
            key={index}
            className="text-base sm:text-lg leading-relaxed text-gray-700 font-sans italic bg-white p-2 rounded shadow-sm"
          >
            {paragraph}
          </p>
        ))
      ))
    );
  };

  return (
    <div className="mt-0 sm:mt-2 bg-gray-100 rounded-lg p-3 shadow-inner border border-gray-300 flex flex-col">
      <div className="relative">
        <div
          ref={contentRef}
          className="max-h-[250px] overflow-y-auto scroll-smooth bg-white rounded p-2"
        >
          {renderContent()}
        </div>
        {!inProgress && <ToggleButton />}
        {!inProgress && (
          <div className="p-2">
            <ContentImprover
              content={content.content}
              onImprovement={handleImprovement}
            />
          </div>
        )}
      </div>
      {!inProgress && (!showWordByWord || isWordByWordComplete) && (
        <ActionButtons />
      )}
    </div>
  );
};

export default DraftContentRenderer;
