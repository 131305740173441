import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import StoryDetails from "../components/StoryDetails";
import { useScene } from "../context/SceneContext";
import { getRandomScenePremise } from "../utils/premiseProvider";
import { generateNewScene } from "../api/scenes";
import { FiLoader } from "react-icons/fi";
import {
  loadLastSceneFromLocalStorage,
  saveSceneToLocalStorage,
} from "../utils/sceneStorageUtils";
import StoryTitle from "../components/StoryTitle";
import Scene from "../components/scene/Scene";
import SceneActionButtons from "../components/scene/SceneActionButtons";
import ContentGenerator from "../components/ContentGenerator";

const AISceneGenerator = () => {
  const location = useLocation();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [title, setTitle] = useState("AI Scene Generator");
  const { state, dispatch } = useScene();
  const [generationMode, setGenerationMode] = useState("open_ended");
  const [isContinueScene, setIsContinueScene] = useState(false);
  const [activeElement, setActiveElement] = useState(null);

  useEffect(() => {
    setTitle("AI Scene Generator");
    loadFromLocalStorage();
    // generateNewPremise();
  }, [location]);

  useEffect(() => {
    if (state.elements.length > 0) {
      saveSceneToLocalStorage({
        title: "Scene 1",
        premise: state.premise,
        elements: state.elements,
      });
    }
  }, [state.elements, state.premise]);

  const loadFromLocalStorage = () => {
    const lastScene = loadLastSceneFromLocalStorage();
    if (lastScene) {
      console.log("Loading scene  from local storage ", lastScene.title);
      dispatch({ type: "SET_TITLE", payload: lastScene.title });
      dispatch({ type: "SET_PREMISE", payload: lastScene.premise });
      dispatch({ type: "SET_ELEMENTS", payload: lastScene.elements });
    } else {
      generateNewPremise();
    }
  };

  const generateNewPremise = () => {
    const premise = getRandomScenePremise();
    dispatch({ type: "SET_PREMISE", payload: premise });
  };

  const generateScene = async () => {
    setIsGenerating(true);
    const instruction = state.premise;
    try {
      let finalElements = await generateNewScene(instruction, (elements) => {
        dispatch({ type: "SET_ELEMENTS", payload: elements });
      });
      console.log(finalElements);
      dispatch({ type: "SET_ELEMENTS", payload: finalElements });
    } catch (error) {
      console.error("Error generating scene:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const toggleGenerationMode = () => {
    setGenerationMode((prevMode) =>
      prevMode === "percentage_based" ? "open_ended" : "percentage_based"
    );
  };

  const toggleContinueScene = useCallback(() => {
    setIsContinueScene((prev) => !prev);
    // If turning on continue scene, scroll to the bottom
    if (!isContinueScene) {
      scrollToBottom();
    }
  }, [isContinueScene]);

  const scrollToBottom = () => {
    const elements = document.querySelectorAll(".scene-element");
    if (elements.length > 0) {
      const lastElement = elements[elements.length - 1];
      lastElement.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const handleSceneFinalize = useCallback(
    (newSceneContent) => {
      console.log(newSceneContent);
      if (newSceneContent) {
        dispatch({ type: "APPEND_ELEMENTS", payload: newSceneContent });
        setIsContinueScene(false);
        scrollToBottom();
      }
    },
    [dispatch]
  );

  const handleElementAction = (action) => {
    if (activeElement !== null) {
      console.log(`Performing ${action} on element ${activeElement}`);
      if (action === "close") {
        setActiveElement(null);
      } else if (action === "delete") {
        dispatch({
          type: "SET_ELEMENTS",
          payload: state.elements.filter((_, index) => index !== activeElement),
        });
        setActiveElement(null);
      } else if (action === "edit") {
        // You might want to implement an edit functionality here
        console.log(
          `Edit functionality for element ${activeElement} not implemented yet`
        );
      } else if (action === "regenerate") {
        const elementToRegenerate = state.elements[activeElement];
        console.log(elementToRegenerate);
        // setIsGenerating(true);
        // generateNewScene(elementToRegenerate.content, (newElement) => {
        //   const updatedElements = [...state.elements];
        //   updatedElements[activeElement] = newElement[0]; // Assuming generateNewScene returns an array
        //   dispatch({
        //     type: "SET_ELEMENTS",
        //     payload: updatedElements,
        //   });
        //   setIsGenerating(false);
        // });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Scene Generator | Create Screenplay Scenes with AI</title>
        <meta
          name="description"
          content="Use our AI Scene Generator to create unique, engaging screenplay scenes with artificial intelligence. Generate creative premises and expand your screenplay effortlessly."
        />
      </Helmet>
      <div className="min-h-screen flex flex-col bg-gray-50">
        <header className="sticky top-0 z-10 bg-white shadow-md transition-all duration-300 sm:py-1">
          <div className="container mx-auto px-4">
            <div className="hidden sm:block mb-4">
              <SceneActionButtons
                toggleContinueScene={toggleContinueScene}
                isContinueScene={isContinueScene}
                isGenerating={isGenerating}
                activeElement={activeElement}
                onElementAction={handleElementAction}
              />
            </div>
            <StoryTitle
              title={title}
              isGeneratingTitle={isGeneratingTitle}
              onGenerateTitle={() => {}}
              isCompact={false}
            />
          </div>
        </header>

        <main className="flex-grow container mx-auto px-4 py-2">
          <div className="max-w-4xl mx-auto">
            <div className="mb-6">
              <StoryDetails
                premise={state.premise}
                onUpdate={(newPremise) =>
                  dispatch({ type: "SET_PREMISE", payload: newPremise })
                }
              />
              {state.elements.length === 0 && !isGenerating && (
                <button
                  onClick={generateScene}
                  className="mt-6 w-full bg-amber-500 hover:bg-amber-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Generate Scene
                </button>
              )}
            </div>

            {isGenerating && (
              <div className="flex justify-center my-8">
                <FiLoader className="animate-spin text-4xl text-amber-500" />
              </div>
            )}

            <Scene
              elements={state.elements}
              setActiveElement={setActiveElement}
              activeElement={activeElement}
            />
          </div>
        </main>

        <footer className="sticky bottom-0 bg-white border-t border-gray-200 mt-2">
          <div className="container mx-auto px-2 max-w-8xl">
            <div className="flex flex-col sm:flex-row items-center justify-between">
              {isContinueScene && (
                <div className="w-full sm:w-auto mb-2 sm:mb-0">
                  <ContentGenerator
                    onFinalize={handleSceneFinalize}
                    mode="continue_scene"
                    title="Add custom instructions to continue scene"
                    onSaveInstruction={() => {}}
                    generationMode={generationMode}
                    countProp={10}
                  />
                </div>
              )}
              <div className="sm:hidden w-full sm:w-auto">
                <SceneActionButtons
                  toggleContinueScene={toggleContinueScene}
                  isContinueScene={isContinueScene}
                  isGenerating={isGenerating}
                  activeElement={activeElement}
                  onElementAction={handleElementAction}
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default AISceneGenerator;
