import React, { useContext, useState, useEffect, useRef } from "react";
import {
  getNewParagraphs,
  getRewriteParagraphs,
  getInsertedParagraphs,
  cancelGeneration,
} from "../api/paragraphs";
import { generateNewScene } from "../api/scenes";

import { getContextForMode } from "../utils/contextUtils";
import ClearableTextarea from "./ClearableTextarea";
import VerticalSlider from "./VerticalSlider";
import {
  FaCheck,
  FaTimes,
  FaCog,
  FaPaperPlane,
  FaToggleOn,
  FaToggleOff,
  FaChevronUp,
  FaChevronDown,
  FaKeyboard,
  FaFastForward,
  FaExpand,
  FaCompress,
} from "react-icons/fa";
import { useStory } from "../context/StoryContext";
import { useScene } from "../context/SceneContext";
import Scene from "./scene/Scene";
import SceneWordByWordRenderer from "./scene/SceneWordByWordRenderer";
import DraftContentRenderer from "./DraftContentRenderer";

const ContentGenerator = ({
  onFinalize,
  mode,
  context = {},
  title = "Generate new paragraphs",
  onGeneratedContent,
  onFinished,
  savedInstruction,
  onSaveInstruction,
  onExpanded,
  generationMode,
  isNsfw = false,
  countProp = 1,
  isExpanded = false,
}) => {
  const { state, dispatch } = useStory();
  const { state: sceneState } = useScene();
  const [instruction, setInstruction] = useState(savedInstruction || "");
  const [draftContent, setDraftContent] = useState(null);
  const [sceneDraftContent, setSceneDraftContent] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [error, setError] = useState(null);
  const [showSlider, setShowSlider] = useState(false);
  const [showInputBar, setShowInputBar] = useState(mode !== "new_paragraphs");
  const [isNSFW, setIsNSFW] = useState(isNsfw);
  const [isCancelling, setIsCancelling] = useState(false);
  const [percentageToAdvance, setPercentageToAdvance] = useState(25);
  const [showWordByWord, setShowWordByWord] = useState(true);
  const [isWordByWordComplete, setIsWordByWordComplete] = useState(false);
  const [showSceneWordByWord, setShowSceneWordByWord] = useState(true);

  const contentRef = useRef(null);

  useEffect(() => {
    setDraftContent(null);
    setCount(1);
    setError(null);
    setShowInputBar(mode !== "new_paragraphs");
  }, [mode]);

  useEffect(() => {
    setIsNSFW(isNsfw);
  }, [isNsfw]);

  const onProgress = (content) => {
    setInProgress(true);
    setDraftContent(content);
    if (mode == "rewrite") {
      onGeneratedContent(content);
    }
  };

  const handleSubmit = async (e, customPercentage = null) => {
    e.preventDefault();
    setIsWordByWordComplete(false);
    onSaveInstruction(instruction);
    setIsLoading(true);
    setError(null);
    setDraftContent(null);
    setSceneDraftContent(null);
    setIsCancelling(false);
    setShowWordByWord(true);
    setShowSceneWordByWord(true);
    try {
      if (mode === "insert") {
        let resolved = await getInsertedParagraphs(
          instruction,
          count,
          context,
          onProgress,
          true,
          isNSFW
        );
        if (resolved) {
          setInProgress(false);
        }
      } else if (mode === "rewrite") {
        const result = await getRewriteParagraphs(
          instruction,
          count,
          context,
          onProgress
        );
        setDraftContent(result);
        onGeneratedContent(result);
        onFinished();
      } else if (mode === "new_paragraphs") {
        const context = getContextForMode(
          "new_paragraphs",
          state,
          0,
          generationMode,
          customPercentage || percentageToAdvance
        );
        // console.log("Context for new paragraphs:", context);
        let resolved = await getNewParagraphs(
          instruction,
          count,
          context,
          generationMode,
          onProgress,
          true,
          isNSFW
        );
        if (resolved) {
          setInProgress(false);
        }
      } else if (mode === "continue_scene") {
        const context = getContextForMode("continue_scene", sceneState);
        console.log("Context for continue scene:", context);
        let resolved = await generateNewScene(instruction, count, context);
        console.log(resolved);
        setSceneDraftContent(resolved);
      }
    } catch (error) {
      if (error.message === "Generation cancelled") {
        setError("Generation was cancelled.");
      } else {
        console.error("Error generating content:", error);
        setError(error.message || "An error occurred while generating content");
      }
      setDraftContent(null);
      setSceneDraftContent(null);
    } finally {
      setIsLoading(false);
      setIsCancelling(false);
    }
  };

  const handleFinalize = (content) => {
    onFinalize(content);
    setInstruction("");
    setDraftContent(null);
    setCount(1);
    if (mode === "new_paragraphs") {
      setShowInputBar(false);
      if (generationMode === "percentage_based") {
        let percentage_to_advance = state.storyProgress + percentageToAdvance;
        if (percentage_to_advance > 100) {
          percentage_to_advance = 100;
        }
        dispatch({
          type: "UPDATE_STORY_PROGRESS",
          payload: {
            percentage: percentage_to_advance,
          },
        });
      }
    } else if (mode === "continue_scene") {
      onFinalize(sceneDraftContent);
      setSceneDraftContent(null);
      setCount(countProp);
    }
  };

  const handleReject = () => {
    setDraftContent(null);
    // setShowInputBar(mode !== "new_paragraphs");
    setSceneDraftContent(null);
    setShowWordByWord(true);
    setShowSceneWordByWord(true);
  };

  const handleCancel = async () => {
    setIsCancelling(true);
    try {
      await cancelGeneration();
    } catch (error) {
      console.error("Error cancelling generation:", error);
    }
  };

  const renderDraftContent = () => {
    if (!draftContent) return null;

    return (
      <DraftContentRenderer
        draftContent={draftContent}
        inProgress={inProgress}
        onFinalize={handleFinalize}
        onReject={handleReject}
      />
    );
  };

  const renderSceneDraftContent = () => {
    if (!sceneDraftContent) return null;

    return (
      <div className="mt-0 sm:mt-2 bg-gray-100 rounded-lg p-3 shadow-inner border border-gray-300">
        <div
          ref={contentRef}
          className="max-h-60 overflow-y-auto scroll-smooth"
        >
          {showSceneWordByWord ? (
            <SceneWordByWordRenderer
              elements={sceneDraftContent}
              typingSpeed={50}
              dialogueTypingSpeed={100}
              dialoguePause={500}
              onComplete={() => setInProgress(false)}
              onWordRendered={() => {
                if (contentRef.current) {
                  contentRef.current.scrollTop =
                    contentRef.current.scrollHeight;
                }
              }}
            />
          ) : (
            <Scene elements={sceneDraftContent} />
          )}
        </div>
        {!inProgress && (
          <div className="flex flex-col sm:flex-row gap-2 mt-2 mb-2">
            <div className="flex-1 flex gap-2">
              <button
                onClick={handleFinalize}
                className="flex-1 px-2 py-1 bg-green-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
              >
                <FaCheck className="mr-1" /> Accept
              </button>
              <button
                onClick={handleReject}
                className="flex-1 px-2 py-1 bg-red-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
              >
                <FaTimes className="mr-1" /> Reject
              </button>
            </div>
            <button
              onClick={() => setShowSceneWordByWord(!showSceneWordByWord)}
              className="w-full sm:w-auto px-2 py-1 bg-blue-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
            >
              <FaFastForward className="mr-1" />{" "}
              {showSceneWordByWord ? "Show All" : "Replay"}
            </button>
          </div>
        )}
      </div>
    );
  };

  const toggleNSFW = () => {
    setIsNSFW(!isNSFW);
  };

  const settingsButton = (
    <button
      type="button"
      onClick={() => setShowSlider(!showSlider)}
      className={`p-2 text-yellow-600 hover:text-yellow-700 transition-colors duration-200 rounded-full shadow-sm flex items-center justify-center ${
        showSlider ? "bg-yellow-200 border-2 border-yellow-400" : "bg-white"
      }`}
      aria-label="Settings"
    >
      <FaCog className="mr-1" />
      {showSlider ? <FaChevronUp size={12} /> : <FaChevronDown size={12} />}
    </button>
  );

  const renderNewParagraphsButtons = () => {
    const suggestionButton = (
      <button
        onClick={() => setShowInputBar(!showInputBar)}
        className={`p-2 text-yellow-600 hover:text-yellow-700 transition-colors duration-200 rounded-full shadow-sm flex items-center justify-center ${
          showInputBar ? "bg-yellow-200 border-2 border-yellow-400" : "bg-white"
        }`}
      >
        <FaKeyboard className="mr-1" />
        {showInputBar ? <FaChevronUp size={12} /> : <FaChevronDown size={12} />}
      </button>
    );

    if (generationMode === "open_ended") {
      return (
        <div className="mb-2">
          <div className="flex flex-row gap-2 items-center">
            {settingsButton}
            <button
              onClick={(e) => handleSubmit(e)}
              className="flex-1 px-4 py-2 bg-amber-500 text-white text-sm font-medium rounded-md hover:bg-amber-600 transition-colors duration-200 flex items-center justify-center"
            >
              <FaPaperPlane className="mr-2" /> Continue Story
            </button>
            {suggestionButton}
          </div>
        </div>
      );
    }

    return (
      <div className="mb-2 pb-2">
        <p className="text-sm font-medium text-gray-700 mb-1">
          Progress story by:
        </p>
        <div className="flex flex-row gap-2">
          {settingsButton}
          <button
            onClick={(e) => {
              setPercentageToAdvance(10);
              handleSubmit(e, 10);
            }}
            className="flex-1 px-2 py-1 bg-amber-400 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-amber-500 transition-colors duration-200 flex items-center justify-center"
          >
            +10%
          </button>
          <button
            onClick={(e) => {
              setPercentageToAdvance(25);
              handleSubmit(e, 25);
            }}
            className="flex-1 px-2 py-1 bg-amber-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-amber-600 transition-colors duration-200 flex items-center justify-center"
          >
            +25%
          </button>
          <button
            onClick={(e) => {
              setPercentageToAdvance(50);
              handleSubmit(e, 50);
            }}
            className="flex-1 px-2 py-1 bg-amber-600 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-amber-700 transition-colors duration-200 flex items-center justify-center"
          >
            +50%
          </button>
          {suggestionButton}
        </div>
      </div>
    );
  };

  const renderInputBar = () => {
    return (
      <form className="space-y-2 pb-2">
        <div className="flex items-center space-x-2">
          {mode !== "new_paragraphs" && settingsButton}
          {!draftContent && (
            <div className="flex-grow relative">
              <ClearableTextarea
                value={instruction}
                onChange={(value) => {
                  setInstruction(value);
                  onSaveInstruction(value);
                }}
                placeholder={`${title}`}
                rows={2}
                isNsfw={isNSFW}
                className="w-full p-3 text-sm border border-yellow-300 rounded-md focus:ring-2 focus:ring-yellow-400 focus:border-transparent pr-12"
              />
              {mode !== "new_paragraphs" && (
                <button
                  type="submit"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors duration-200 flex items-center justify-center"
                  disabled={isLoading}
                  onClick={(e) => handleSubmit(e)}
                >
                  <FaPaperPlane className="w-4 h-4 sm:mr-1" />
                  <span className="hidden sm:inline">
                    {isLoading
                      ? "Processing..."
                      : mode === "rewrite"
                      ? "Rewrite"
                      : "Generate"}
                  </span>
                </button>
              )}
            </div>
          )}
          {draftContent && (
            <div className="px-3 py-2 bg-yellow-50 border border-yellow-200 rounded-md flex items-center gap-2 cursor-pointer hover:bg-yellow-100 transition-colors group">
              <span className="text-gray-600 italic line-clamp-1 group-hover:line-clamp-none">
                {instruction || "No instruction provided"}
              </span>
            </div>
          )}
        </div>
      </form>
    );
  };

  const renderSettings = () => {
    return (
      <div className="p-1 bg-yellow-100 border border-yellow-300 rounded-md shadow-inner">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            {mode === "new_paragraphs" && (
              <button
                onClick={() => {
                  onExpanded();
                }}
                className="p-2 text-yellow-600 hover:text-yellow-700 transition-colors duration-200 rounded-full shadow-sm flex items-center justify-center bg-white"
              >
                {isExpanded ? <FaCompress size={14} /> : <FaExpand size={14} />}
              </button>
            )}
            <div>
              <VerticalSlider value={count} onChange={setCount} />
            </div>
            <span className="hidden sm:block text-sm text-yellow-700">
              Paragraphs: {count}
            </span>
          </div>
          <button
            type="button"
            onClick={toggleNSFW}
            className="flex items-center space-x-2 text-sm mr-2"
          >
            <span className="text-yellow-700">NSFW</span>
            {isNSFW ? (
              <FaToggleOn className="text-yellow-500 text-xl" />
            ) : (
              <FaToggleOff className="text-gray-400 text-xl" />
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`bg-gradient-to-r from-yellow-50 to-yellow-100 px-2 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out`}
    >
      {mode === "new_paragraphs" && renderNewParagraphsButtons()}
      {showSlider && renderSettings()}
      {(showInputBar || mode !== "new_paragraphs") && renderInputBar()}

      {isLoading && (
        <div className="flex items-center justify-between py-2 px-3 bg-yellow-100 rounded-md text-sm">
          <div className="flex items-center">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-yellow-500 mr-2"></div>
            <span className="text-yellow-700">Generating...</span>
          </div>
          <button
            onClick={handleCancel}
            className="text-red-600 hover:text-red-800 transition-colors duration-200 text-sm"
            disabled={isCancelling}
          >
            {isCancelling ? "Cancelling..." : "Cancel"}
          </button>
        </div>
      )}

      {error && (
        <div
          className="mt-2 bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded-md text-sm"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span>{error}</span>
        </div>
      )}

      {(mode === "insert" || mode === "new_paragraphs") && renderDraftContent()}
      {mode === "continue_scene" && renderSceneDraftContent()}
    </div>
  );
};

export default ContentGenerator;
