import React, { useState, useEffect } from "react";
import {
  FiEdit,
  FiRefreshCw,
  FiPlus,
  FiTrash2,
  FiX,
  FiDownload,
  FiCopy,
  FiFeather,
  FiType,
  FiList,
  FiSettings,
} from "react-icons/fi";
import {
  FaToggleOn,
  FaToggleOff,
  FaSync,
  FaEdit,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const SceneActionButtons = ({
  toggleContinueScene,
  isContinueScene,
  isGenerating,
  toggleGenerationMode,
  generationMode,
  activeElement,
  onElementAction,
}) => {
  const [openSlider, setOpenSlider] = useState(null);
  const [activeSliderOption, setActiveSliderOption] = useState(null);

  useEffect(() => {
    if (activeElement !== null && activeElement !== undefined) {
      setOpenSlider("Element");
    } else {
      setOpenSlider(null);
    }
  }, [activeElement]);

  const toggleSlider = (slider) => {
    setOpenSlider(openSlider === slider ? null : slider);
  };

  const renderSliderContent = (options) => (
    <div className="flex space-x-1 overflow-x-auto">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            option.onClick(e);
          }}
          className={`py-1 px-2 rounded-full whitespace-nowrap transition-all duration-200 flex items-center text-xs ${
            option.isActive
              ? "bg-yellow-500 text-white"
              : "bg-yellow-100 text-yellow-700"
          } ${isGenerating ? "opacity-50 cursor-not-allowed" : ""}`}
          disabled={isGenerating}
        >
          {option.icon}
          {option.isActive && option.activeLabel && (
            <span className="ml-1">{option.activeLabel}</span>
          )}
        </button>
      ))}
    </div>
  );

  const sliderOptions = {
    Edit: [
      {
        label: null,
        icon: isContinueScene ? <FiFeather size={18} /> : <FiType size={18} />,
        onClick: (e) => {
          e.stopPropagation();
          toggleContinueScene();
        },
        isActive: isContinueScene,
      },
    ],
    Element: [
      {
        icon: <FaPlus size={18} />,
        onClick: () => {
          setActiveSliderOption("insert");
          onElementAction("insert");
        },
        activeLabel: "Insert",
        isActive: activeSliderOption === "insert",
      },
      {
        icon: <FaSync size={18} />,
        onClick: () => {
          setActiveSliderOption("regenerate");
          onElementAction("regenerate");
        },
        activeLabel: "Regenerate",
        isActive: activeSliderOption === "regenerate",
      },
      {
        icon: <FaTrash size={18} />,
        onClick: () => {
          setActiveSliderOption("delete");
          onElementAction("delete");
        },
        activeLabel: "Delete",
        isActive: activeSliderOption === "delete",
      },
    ],
  };

  const renderToggleButton = (slider) => {
    if (slider === "Element" && activeElement === null) {
      return null;
    }

    const Icon =
      slider === "Edit"
        ? FiEdit
        : slider === "Manage"
        ? FiPlus
        : slider === "Export"
        ? FiDownload
        : slider === "Element"
        ? FiSettings
        : null;

    return (
      <button
        key={slider}
        onClick={(e) => {
          e.stopPropagation();
          toggleSlider(slider);
        }}
        className={`flex items-center justify-center transition-all duration-300 ease-in-out p-1 shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 rounded-full ${
          openSlider === slider
            ? "bg-gray-500 text-white w-8 h-8"
            : "bg-white text-gray-600 hover:bg-gray-50 focus:ring-gray-300 w-6 h-6"
        } ${isGenerating ? "opacity-50 cursor-not-allowed" : ""}`}
        disabled={isGenerating}
        aria-label={slider}
      >
        <Icon size={openSlider === slider ? 18 : 14} />
      </button>
    );
  };

  return (
    <div className="relative" onClick={(e) => e.stopPropagation()}>
      <div className="flex items-center justify-between w-full space-x-2">
        <div className="flex items-center space-x-2">
          <Link
            to="/scenes"
            className="flex items-center justify-center bg-white text-blue-500 hover:bg-blue-50 transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            aria-label="View Scenes"
          >
            <FiList size={18} />
            <span className="ml-1 text-xs hidden sm:inline">Scenes</span>
          </Link>
          <button
            onClick={toggleGenerationMode}
            className="hidden sm:flex items-center justify-center bg-white text-yellow-500 hover:bg-yellow-50 transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
          >
            {generationMode === "percentage_based" ? (
              <FaToggleOn size={18} />
            ) : (
              <FaToggleOff size={18} />
            )}
            <span className="ml-1 text-xs">
              {generationMode === "percentage_based"
                ? "Progress"
                : "Open Ended"}
            </span>
          </button>
        </div>
        <div className="flex items-center space-x-2 bg-white p-2 rounded-lg shadow-sm">
          <div className="flex items-center space-x-1">
            {openSlider ? (
              <>
                {renderToggleButton(openSlider)}
                {renderSliderContent(sliderOptions[openSlider])}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenSlider(null);
                    setActiveSliderOption(null);
                    onElementAction("close");
                  }}
                  className="bg-red-200 text-gray-600 rounded-full p-1 shadow-md focus:outline-none w-6 h-6 flex items-center justify-center"
                  aria-label="Close"
                >
                  <FiX size={14} />
                </button>
              </>
            ) : (
              ["Edit"].map(renderToggleButton).filter(Boolean)
            )}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            resetStory();
          }}
          className="flex items-center justify-center bg-white text-red-500 hover:bg-red-50 transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          aria-label="Reset Story"
        >
          <FiRefreshCw size={18} />
          <span className="ml-1 text-xs hidden sm:inline">Reset</span>
        </button>
      </div>
    </div>
  );
};

export default SceneActionButtons;
