import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export const getPublicStories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/stories/public`);
    return response.data;
  } catch (error) {
    console.error("Error fetching public stories:", error);
    throw error;
  }
};

export const getPrivateStories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/stories/private`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching private stories:", error);
    throw error;
  }
};

export const getStoryById = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/${API_VER}story/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching story:", error);
    throw error;
  }
};

export const saveStory = async (story) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/stories`, story, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error saving story:", error);
    throw error;
  }
};

export const updateStory = async (id, story) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/stories/${id}`, story, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating story:", error);
    throw error;
  }
};

export const deleteStory = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/stories/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.error("Error deleting story:", error);
    throw error;
  }
};

const API_VER = "api/v1/";
const API_ENDPOINTS = {
  GENERATE_TITLE: API_VER + "story/title",
  API_CALLS_HISTORY: API_VER + "api-calls-history",
};

export const generateTitle = async (storyContent) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.GENERATE_TITLE}`,
      {
        context: storyContent,
      }
    );
    return response.data.title.text;
  } catch (error) {
    console.error("Error generating title:", error);
    throw error;
  }
};

// Add this new function to fetch API calls history
export const getApiCallsHistory = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.API_CALLS_HISTORY}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API calls history:", error);
    throw error;
  }
};

export const generatePremise = async (context) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${API_VER}parameters/suggestions`,
      {
        fieldType: "Premise",
        currentValue: "",
        context: context,
      }
    );
    return response.data.suggestions;
  } catch (error) {
    console.error("Error generating premise:", error);
    throw error;
  }
};

export const saveStoryToFile = async (storyState, publishedStoryId) => {
  try {
    // filter only the strings from the paragraphs array
    let paragraphs = storyState.paragraphs.filter(
      (paragraph) => typeof paragraph === "string"
    );
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${API_VER}story/save`,
      {
        id: publishedStoryId,
        title: storyState.title,
        premise: storyState.premise,
        genre: storyState.genre,
        paragraphs: paragraphs,
        storyProgress: storyState.storyProgress,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving story to file:", error);
    throw error;
  }
};

export const publishStory = async (storyId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${API_VER}story/publish`,
      { id: storyId }
    );
    return response.data;
  } catch (error) {
    console.error("Error publishing story:", error);
    throw error;
  }
};

export const getPublishedStories = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/${API_VER}stories/published`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching published stories:", error);
    throw error;
  }
};
