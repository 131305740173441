import React from 'react';
import { FaParagraph } from 'react-icons/fa';

const VerticalSlider = ({ value, onChange, min = 1, max = 5 }) => {
  const handleChange = (e) => {
    onChange(parseInt(e.target.value));
  };

  return (
    <div className="flex items-center bg-yellow-100 rounded-md p-2">
      <FaParagraph className="text-yellow-600 mr-2" />
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        className="w-20 h-1 appearance-none bg-yellow-300 rounded-full outline-none"
      />
      <span className="text-yellow-700 ml-2 min-w-[1.5rem] text-center">
        {value}
      </span>
    </div>
  );
};

export default VerticalSlider;